import {
  Alignment,
  Hero as HeroSDS,
  SkeletonSummaryCard,
} from '@snapchat/snap-design-system-marketing';
import { type FC, useMemo } from 'react';

import { SummaryCard } from '../../../../components/SummaryCard';
import { summaryCardHeroCss } from '../../../../components/SummaryCard/SummaryCard.styled';
import {
  renderRichTextMultiLineWithMarkings,
  renderRichTextWithEmbeddingsNoHeadings,
} from '../../../../utils/renderText/renderRichText';
import type { LatestGalleryCardHeroProps } from './types';
import { useLatestGalleryTile } from './useLatestGalleryTile';

export const LatestGalleryCardHero: FC<LatestGalleryCardHeroProps> = ({
  title,
  body,
  eyebrowText,
  galleryId,
  defaultTileImage,
  analytics,
}) => {
  const { tile, isLoading } = useLatestGalleryTile(galleryId);

  const mediaBlock = useMemo(() => {
    if (isLoading) {
      return <SkeletonSummaryCard className={summaryCardHeroCss} />;
    }

    if (!tile) return undefined;

    return (
      <SummaryCard
        {...tile}
        showDates={!eyebrowText}
        showDescriptions
        media={tile.media ?? defaultTileImage}
        analytics={analytics ?? tile.analytics}
        isHeroTile
        eyebrowText={eyebrowText}
      />
    );
  }, [isLoading, tile, eyebrowText, defaultTileImage, analytics]);

  return (
    <HeroSDS
      title={renderRichTextMultiLineWithMarkings(title)}
      body={renderRichTextWithEmbeddingsNoHeadings(body)}
      foregroundMedia={mediaBlock}
      textAlign={Alignment.Center}
      textAlignMobile={Alignment.Center}
      showMediaMobile
    />
  );
};

LatestGalleryCardHero.displayName = 'LatestGalleryCardHero';
