import { Partition } from '@snapchat/graphene';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';

export const config: SiteConfiguration = {
  domainName: 'newsroom.snap.com',

  redirects: {
    hostRedirects: {},
  },

  theme: {},

  globalNavProps: {
    defaultGroupKey: 'snap',
  },
  trackingSettings: {
    cookieDomain: 'snap.com',
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.NEWSROOM },
      {
        vendor: LoggingVendor.GOOGLE,
        googleTagManagerId: 'GTM-PB72WQ3',
        googleAnalyticsId: 'G-RQ743265LZ',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'press-snap-com',
        dsn: 'https://4ef29882c82f43f090b6201d81899ff9@sentry.sc-prod.net/72',
      },
    ],
  },
  search: true,
};
